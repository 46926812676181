<script setup lang="ts">
import { UI05FormElement, UI05Textarea } from 'ui-05'
import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
import { useElementSize, useScroll } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/modal/auth'
import { useProductStore } from '~/store/modal/product'
import { useUserStore } from '~/store/user'
import { useCartStore } from '~/store/cart'
import { useServiceStore } from '~~/store/service'
import type { Product } from '~/type/product/Product'
import type { CheckoutPrice } from '~/type/order/Checkout'
import { useClearBasketStore } from '~~/store/modal/clearBasket'

interface Props {
  /**
   * Состояние активности оформления (нужно для состояния кнопки "Оплатить" )
   */
  isCheckoutActive: boolean
  /**
   * Есть ли хоть один товар, которого нет в наличии
   */
  isProductsUnavailable: boolean
  list: Product[]
  otherProducts: Product[]
  price: CheckoutPrice
  freeDeliveryPrice: number
  /**
   * Состояние загрузки оформления заказа
   */
  orderCreateLoading?: boolean
  error?: boolean
  initialComment?: string
}

withDefaults(defineProps<Props>(), {
  active: false,
  error: false,
  orderCreateLoading: false,
  freeDeliveryPrice: 0,
  initialComment: '',
})
const emit = defineEmits<{
  (event: 'on-pay-click' | 'on-checkout-click'): void
  (event: 'on-delete', value: number): void
  (event: 'cart-is-empty'): void
  (event: 'comment-change', value: string): void
}>()
const { $api } = useNuxtApp()

const userStore = useUserStore()
const cartStore = useCartStore()
const authStore = useAuthStore()
const productModalStore = useProductStore()
const clearBasket = useClearBasketStore()

const cartEl = shallowRef()
const productListEl = shallowRef()
const { arrivedState: arrivedStateMobile } = useScroll(window, {
  offset: {
    bottom: 10,
  },
})
const { arrivedState } = useScroll(cartEl)
const { height: cartHeight } = useElementSize(cartEl)
const { height: productListHeight } = useElementSize(productListEl)

const userStoreData = computed(() => cloneDeep(userStore.data))
const notConfirmPhone = computed(() => !userStore.data?.confirm?.phone)
const { isUser, isGuest } = storeToRefs(userStore)
const { cartAmount } = storeToRefs(cartStore)
const { isWorkingHours } = storeToRefs(useServiceStore())

let unsubscribe: () => void | undefined
onMounted(() => {
  unsubscribe = cartStore.$onAction((mutation) => {
    if (mutation.name === 'setCart' || mutation.name === 'reset') {
      mutation.after(() => {
        if (mutation.store.cartAmount === 0)
          emit('cart-is-empty')
      })
    }
  })
})
onUnmounted(() => {
  unsubscribe?.()
})

const verifyCode = await useActionEntity($api.auth.sendVerificationCode, {
  params: {},
})

const confirmCode = await useActionEntity($api.auth.verifyPhone, {
  params: {
    code: '',
  },
  onSuccess: () => {
    const data = merge(userStoreData.value, {
      confirm: {
        phone: true,
      },
    })

    userStore.set(data)
  },
})
</script>

<template>
  <div class="cart">
    <h2 class="cart__title">
      Корзина
    </h2>
    <Unavailable
      v-if="cartAmount === 0"
      icon="darkotik"
      class="cart__overlay cart__overlay_empty"
    >
      <p class="cart__description">
        Даркотик ждет пока вы добавите товары
      </p>
    </Unavailable>
    <div v-else-if="isUser && notConfirmPhone" class="cart__overlay">
      <Unavailable icon="darkotik">
        <PhoneConfirmation
          class="mb-25"
          :verify-status="verifyCode.status.value"
          :confirm-status="confirmCode.status.value"
          :verify-error-value="verifyCode.errorValue.value"
          :confirm-error-value="confirmCode.errorValue.value"
          @submit="verifyCode.request()"
          @confirm="confirmCode.request({ code: $event })"
        />
      </Unavailable>
    </div>
    <div
      v-else
      ref="cartEl"
      class="cart__product"
      :class="{ cart__product_unavailable: !isWorkingHours || isProductsUnavailable }"
    >
      <button
        v-if="cartAmount"
        class="cart__delete-all"
        @click="clearBasket.enable()"
      >
        Очистить
      </button>
      <CCCartProductList
        v-slot="{ product }"
        ref="productListEl"
        :list="list"
      >
        <CCCartProduct
          :class="{ 'cart__unavailable-product': cartStore.showOverlay }"
          :product="product"
          @decrease="$emitter.emit('cart-product-remove', { product: { ...product, quantity: product.quantity }, cart: cartStore.state! })"
          @open="productModalStore.enable({ active: $event.status, id: $event.id, path: $event.path })"
        />
      </CCCartProductList>
    </div>
    <div v-if="!isWorkingHours" class="cart__content-buttons">
      <div
        class="cart__content-button cart__content-button_off-hours"
      >
        Сейчас мы не доставляем
        <p class="mr-5" data-e2e="total-cost">
          {{ price.total }}₽
        </p>
      </div>
    </div>
    <div v-else-if="cartAmount === 0" class="cart__content-buttons cart__content-buttons_empty">
      <div class="cart__content-button cart__content-button_empty">
        Добавьте товар в корзину <span class="cart__content-button-price">0 ₽</span>
      </div>
    </div>
    <div v-else-if="isProductsUnavailable" class="cart__content-buttons cart__content-buttons_not-available">
      Удалите товары которых нет в наличии,
      чтобы перейти к оформлению заказа
    </div>
    <div
      v-else-if="cartAmount"
      class="cart__content-buttons"
      :class="{ 'cart__content-buttons_checkout-active': isCheckoutActive }"
    >
      <template v-if="productListHeight > cartHeight && !isCheckoutActive">
        <div v-if="!arrivedState.bottom" class="cart__scroll-overlay cart__scroll-overlay_desktop" />
        <div v-if="!arrivedStateMobile.bottom && !otherProducts.length" class="cart__scroll-overlay cart__scroll-overlay_mobile" />
      </template>
      <template v-if="!isCheckoutActive">
        <div class="checkout-controller__warehouse-comment">
          <UI05FormElement
            title="Комментарий для сборщика"
            hint-min-height="0"
          >
            <UI05Textarea
              :model-value="initialComment"
              placeholder="Любые детали, которые важны при выборе продуктов"
              data-e2e="checkout-comment-warehouse"
              :height="60"
              dynamical-height
              @update:model-value="emit('comment-change', $event)"
            />
          </UI05FormElement>
        </div>
        <div class="cart__free-delivery">
          <CCFreeDeliveryRange
            :delivery="price.delivery"
            :total-price="price.product"
            :free-delivery="freeDeliveryPrice"
          />
        </div>
      </template>
      <!-- блок с уведомлением о возможной задержке, сейчас не нужен -->
      <!-- <div v-if="isWorkingHours && Date.now() < Date.parse('2024-04-15T00:00:00+03:00')" class="cart__delivery-note">
        <img
          src="./asset/delivery-shipment.svg"
          alt="Заказ задерживается"
          class="mr-6"
        >
        <span>Время доставки увеличено</span>
      </div> -->
      <div
        v-if="isGuest"
        class="cart__content-button cart__content-button_auth"
        @click="authStore.enable"
      >
        Авторизоваться
      </div>
      <div v-else-if="orderCreateLoading" class="cart__content-button">
        <div class="cart__content-button-text cart__content-button-text_loading" />
      </div>
      <div
        v-else-if="isCheckoutActive"
        class="cart__content-button-text cart__content-button"
        @click="emit('on-pay-click')"
      >
        Оплатить
        <p class="mr-5">
          <span data-e2e="discount-total-cost"> {{ price.total }}₽</span>
          <span v-if="price.sale" class="cart__content-button-text-without-discounts">{{ price.total + price.sale }}₽</span>
        </p>
      </div>
      <div
        v-else
        class="cart__content-button cart__content-button-text"
        @click="emit('on-checkout-click')
        "
      >
        Оформить заказ
        <span class="cart__content-button-price"> {{ price.total }}₽</span>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.cart {

  &__scroll-overlay {
    position: absolute;
    top: -99px;
    left: 0;
    width: 100%;
    height: 99px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);

    &_mobile {
      display: none;
    }
  }

  &__delete-all {
    position: absolute;
    top: 23px;
    left: calc(100% - 100px);
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    background: transparent;
    border: none;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }

  &__description {
    width: 160px;
    font-size: 16px;
    line-height: 22px;
  }

  &__unavailable-product {
    pointer-events: none;
    opacity: 0.3;
  }

  &__delivery-note {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__error {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    right: 0px;
    left: 0px;
    z-index: var(--z-blackout);
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 110px);
    background-color: rgba(255, 255, 255, 0.9);

    &_empty {
      height: 100%;
      background-color: #fff;
      backdrop-filter: none;
    }
  }

  &__product {
    flex-grow: 1;
    height: calc(100vh - 450px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &_unavailable {
      height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }

  &__title {
    margin: 20px 0 5px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  &__free-delivery {

    &_mobile {
      display: none;
    }
  }

  &__trash-icon {
    width: 23px;
    height: 23px;
    margin-left: auto;
    cursor: pointer;
    background-image: svg-load('./asset/trash.svg', fill=transparent);
    background-repeat: no-repeat;
  }

  &__content-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 21px;

    &_empty {
      z-index: var(--z-modal);
    }

    &_not-available {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #f44b4c;
    }
  }

  &__content-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 21px 15px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
    background-color: #e30613;
    border-radius: 22px;

    &_empty {
      justify-content: space-between;
      color: #818181;
      background-color: #f8f8f8;
    }

    &_auth {
      font-size: 18px;
      font-weight: 500;
      background-color: #fdca54;
    }

    &_off-hours {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      color: #000;
      text-align: center;
      background-color: #eee;

      p.mr-5 {
        display: none;
      }

      @media (max-width: 1000px) {
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;

        p.mr-5 {
          display: block;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }

  &__content-button-price {
    flex-shrink: 0;
  }

  &__content-button-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &_loading {
      height: 22px;
      pointer-events: none;
      background-image: url(./asset/loading-dots.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 13%;
    }

    &-without-discounts {
      position: relative;
      margin-left: 10px;
      font-size: 20px;
      line-height: 23px;
      color: rgb(255, 255, 255, 0.5);

      &::before {
        position: absolute;
        top: 45%;
        left: -2px;
        width: 100%;
        height: 1px;
        content: '';
        background-color: #fff;
        border-radius: 10px;
        transform: rotate(-17deg);
      }
    }
  }

  @media (max-width: 1100px) {

    &__delete-all {
      left: calc(100% - 90px);
    }
  }

  @media (max-width: 1000px) {

    &__delete-all {
      top: 18px;
      left: calc(100% - 85px);
    }

    &__scroll-overlay {

      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    &__delivery-note {
      margin-bottom: 10px;
    }

    &__title-wrap {
      padding-bottom: 10px;
    }

    &__title {
      margin: 15px 0 5px;
      font-size: 20px;
      line-height: 23px;
    }

    &__overlay {
      position: static;
      height: auto;
    }

    &__product {
      height: 100%;
      overflow-y: inherit;
    }

    &__content-buttons {
      position: fixed;
      z-index: var(--z-blackout);
      padding: 20px 10px 20px;
      margin-bottom: 65px;
      border-radius: 20px 20px 0 0;

      &_checkout-active {
        margin-bottom: 0;
      }
    }
  }
}
</style>
