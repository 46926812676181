<script setup lang="ts">
import { UI05Toggle } from 'ui-05'

defineProps<{
  active: boolean
}>()

const emit = defineEmits<{
  (e: 'click', value: boolean): void
}>()
</script>

<template>
  <UI05Toggle :initial-value="active">
    <template #default>
      <button
        class="toggle-button"
        :class="[active ? 'toggle-button_active' : 'toggle-button_non-active']"
        @click="emit('click', !active)"
      >
        <div class="toggle-button__toggler" />
      </button>
    </template>
  </UI05Toggle>
</template>

<style lang="postcss">
.toggle-button {
  position: relative;
  width: 51px;
  height: 31px;
  padding: 2px;
  cursor: pointer;
  border: none;
  border-radius: 31px;

  &_active {
    background-color: #34C759;

    .toggle-button__toggler {
      top: 2px;
      transform: translateX(20px);
    }
  }

  &_non-active {
    background-color: #787880;

    .toggle-button__toggler {
      top: 2px;
      transform: translateX(0px);
    }
  }

  &__toggler {
    position: absolute;
    top: 2px;
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 27px;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}
</style>
